import $ from 'jquery';


export default class OverlayMenu {


	constructor() {
		this.state = false;
		this.$el = $('.p-overlayMenu');
		this.$controller =  $('[data-overlay-menu-controller]');
		this.on();
	}

	on() {
		this.$controller.on('click', this.toggle.bind(this));
	}

	toggle(event) {
		event.preventDefault();
		this.state = !this.state;
		if (this.state) {
			this.open();
		} else {
			this.close();
		}
	}

	open() {
		this.$el.addClass("p-overlayMenu--open");
	}

	close() {
		this.$el.removeClass("p-overlayMenu--open");
	}

	static init() {

		new OverlayMenu()
	}

}