import $ from 'jquery';
import mvSlider from './mvSlider';
import featureSlider from './featureSlider';
import Collapse from './Collapse';
import TabBox from './TabBox';
import OverlayMenu from './OverlayMenu';

$(function(){
	"use strict";

	mvSlider();
	featureSlider();

	OverlayMenu.init();
	Collapse.init();
	TabBox.init();

	$("[data-go-page-top]").on('click', function(event){
		event.preventDefault();
		$('html,body').animate({ scrollTop: 0 }, 500, 'easeOutExpo' );
	});

});

//FAQ用のアコーディオンtoggle
$(function(){
		$("dt#p-faq__title").on("click", function() {
			$(this).next().slideToggle('fast');
		});
});

$(function(){
	$('dt#p-faq__title').on('click', function(){
		$(this).toggleClass('refrect-arrow').children('.p-faq_arrow');
	});
});
