import $ from 'jquery';


export default class CollapseContents {


	constructor($el) {
		this.$el = $el;
		this.state = false;
		this.$target = $el.find('.p-collapseContents__body');
		this.$controller =  $el.find('.p-collapseContents__controller');
		this.initialHeight = this.$target.height();
		this.setupFullHeight();
		this.on();
	}

	setupFullHeight() {
		this.$target.css('height',"auto");
		this.fullHeight = this.$target.height();
		this.$target.height("");
	}

	on() {
		$(window).resize( (event) => { this.setupFullHeight() } );
		this.$controller.on('click', (event) => { this.toggle(event) } );
		this.$target.on('transitionend',  () => {
			this.$el.removeClass("p-collapseContents--animating");
			this.$target.height("");
		});
	}

	toggle(event) {
		event.preventDefault();
		this.state = !this.state;
		if (this.state) {
			this.open();
		} else {
			this.close();
		}
	}

	open() {
		var height;
		this.$el.addClass("p-collapseContents--open");
		this.$target.height(this.initialHeight);
		this.$el.addClass("p-collapseContents--animating");
		this.$target.height(this.fullHeight);
	}

	close() {
		this.$el.addClass("p-collapseContents--animating");
		this.$target.height(this.$target.height());
		this.$target.height(this.initialHeight);
		this.$el.removeClass("p-collapseContents--open");
	}

	static init() {

		$(".p-collapseContents").each(function() {
			return new CollapseContents($(this));
		});
	}

}